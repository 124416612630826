import React from 'react';
import { IconApplePay } from '../../../assets/icons/icon-apple-pay.tsx';
import { IconBankTransfer } from '../../../assets/icons/icon-bank-transfer.tsx';
import { IconCreditCard } from '../../../assets/icons/icon-credit-card.tsx';
import { IconIdeal } from '../../../assets/icons/icon-ideal.tsx';
import { IconPaypal } from '../../../assets/icons/icon-paypal.tsx';
import { PaymentMethods } from '../../constants/payment-methods.ts';

interface PaymentMethodIconProps {
  method: PaymentMethods;
  className?: string;
}

export function PaymentMethodIcon({ method, ...props }: PaymentMethodIconProps): React.ReactNode {
  if (method === PaymentMethods.ApplePay) {
    return <IconApplePay aria-hidden="true" {...props} />;
  }
  if (method === PaymentMethods.BankTransfer) {
    return <IconBankTransfer aria-hidden="true" {...props} />;
  }
  if (method === PaymentMethods.CreditCard) {
    return <IconCreditCard aria-hidden="true" {...props} />;
  }
  if (method === PaymentMethods.Ideal) {
    return <IconIdeal aria-hidden="true" {...props} />;
  }
  return <IconPaypal aria-hidden="true" {...props} />;
}
