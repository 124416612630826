import cx, { clsx } from 'clsx';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import type { Section as SectionType } from '../../types';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import { ItemTable } from './item-table.tsx';
import styles from './section.strict-module.css';

interface SectionProps {
  index: number;
  section: SectionType;
  showSubtotal: boolean;
}

export function Section({ index, section, showSubtotal }: SectionProps): ReactNode {
  return (
    <div className={styles.section} data-testid={`pdf-preview-section-${index}`}>
      {section.title ? (
        <div
          className={cx('label-1', !section.description && 'mb-8')}
          data-testid="pdf-preview-section-title"
        >
          {section.title}
        </div>
      ) : null}

      {section.description ? (
        <div
          className={cx('mb-8', 'body-1', styles.description)}
          data-testid="pdf-preview-section-description"
        >
          {section.description}
        </div>
      ) : null}

      <ItemTable items={section.items} />

      {showSubtotal ? <SectionSubtotals totalExcludingVat={section.totalExcludingVat} /> : null}
    </div>
  );
}

interface SectionSubtotalsProps {
  totalExcludingVat?: string;
}

function SectionSubtotals({ totalExcludingVat }: SectionSubtotalsProps): ReactNode {
  const { formatMessage, formatNumber } = useIntl();
  const { displayLanguage, currency } = usePdfPreviewContext();

  return (
    <table className={clsx('mb-8', styles.totals)}>
      <tbody>
        <tr>
          <td className={clsx(styles.cell, 'body-1-bold')}>
            {formatMessage(
              { id: 'pdf-preview.section-subtotal-base' },
              { language: displayLanguage }
            )}
          </td>
          <td
            className={clsx(styles.cell, styles.amount, 'body-1')}
            data-testid="pdf-preview-section-subtotal-excl-vat"
          >
            {formatNumber(Number(totalExcludingVat) || 0, {
              style: 'currency',
              currency,
              minimumFractionDigits: 2,
            })}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
