import type { ReactNode } from 'react';
import { usePdfPreviewContext } from '../pdf-preview-context.tsx';
import { Placeholder } from '../placeholders';
import type { Document, MerchantDetails } from '../types';
import { PLACEHOLDER_SECTIONS } from '../../constants/pdf-preview.ts';
import { FooterDetails } from './internals/footer-details.tsx';
import { PageNumber } from './internals/page-number.tsx';
import { PaymentDetails } from './internals/payment-details.tsx';
import { PaymentLink } from './internals/payment-link.tsx';
import { TermsAndConditions } from './internals/terms-and-conditions.tsx';
import styles from './footer.strict-module.css';

interface FooterProps {
  document: Document;
  merchant: MerchantDetails;
  paymentLink?: string | null;
}

export function Footer({ document, merchant, paymentLink }: FooterProps): ReactNode {
  const { type, variant } = usePdfPreviewContext();

  if (variant === 'DE') {
    return (
      <footer className={styles['de-layout']}>
        <PaymentLink className={styles.paymentLink} paymentLink={paymentLink} />
        <PageNumber />
        <FooterDetails
          contactEmail={document.contactEmail}
          document={document}
          merchant={merchant}
        />
      </footer>
    );
  }

  const showPaymentDetails = type === 'invoice' && !paymentLink;

  return (
    <Placeholder
      className={styles.layout}
      data-testid="pdf-preview-footer-placeholder"
      height="206px"
      section={PLACEHOLDER_SECTIONS.FOOTER}
      width="100%"
    >
      <footer className={styles.layout}>
        <PaymentLink paymentLink={paymentLink} />

        {showPaymentDetails ? (
          <PaymentDetails
            beneficiaryName={document.beneficiaryName}
            bic={document.bic}
            iban={document.iban}
            reference={document.reference}
          />
        ) : null}

        {paymentLink || showPaymentDetails ? <div className={styles.divider} /> : null}

        <TermsAndConditions merchant={merchant} termsAndConditions={document.termsAndConditions} />
      </footer>
    </Placeholder>
  );
}
