import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import { AdditionalNotesPlaceholder } from '../placeholders';
import styles from './additional-notes.strict-module.css';

interface AdditionalNotesProps {
  message?: string;
}

export function AdditionalNotes({ message }: AdditionalNotesProps): ReactNode {
  return (
    <AdditionalNotesPlaceholder>
      {message ? (
        <p
          className={clsx('body-1', 'mb-8', styles.notes)}
          data-testid="pdf-preview-additional-notes"
        >
          {message}
        </p>
      ) : null}
    </AdditionalNotesPlaceholder>
  );
}
