import { formatDate, Tooltip } from '@repo/design-system-kit';
import { IconDotsOutlined, IconShortcutCreateInvoiceOutlined } from '@repo/monochrome-icons';
import { clsx } from 'clsx';
import { Button as AriaButton, Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { IconApplePay } from '../../../assets/icons/icon-apple-pay';
import { IconCreditCard } from '../../../assets/icons/icon-credit-card';
import { IconIdeal } from '../../../assets/icons/icon-ideal';
import { IconPaypal } from '../../../assets/icons/icon-paypal';
import type { StandalonePaymentMethod } from '../../types';
import styles from './payment.strict-module.css';

export interface PaymentProps {
  id: string;
  paymentMethod: StandalonePaymentMethod;
  userEmail: string;
  createdAt: string;
  onClickCreateInvoice?: () => void;
}

const methodIcons: Record<StandalonePaymentMethod, React.ReactNode> = {
  credit_card: <IconCreditCard />,
  apple_pay: <IconApplePay />,
  paypal: <IconPaypal />,
  ideal: <IconIdeal />,
};

export function Payment({
  payment,
  onClickCreateInvoice,
}: {
  payment: PaymentProps;
  onClickCreateInvoice?: () => void;
}): React.ReactElement {
  const { formatMessage, locale } = useIntl();

  return (
    <div className={styles.payment} data-test-related-payment-item key={payment.id}>
      <div className={styles.method}>{methodIcons[payment.paymentMethod]}</div>
      <div className={styles.info}>
        <span className={clsx(styles.from, 'body-2')}>
          {formatMessage(
            { id: 'payment-links.sidebar.related-payments.from' },
            {
              userEmail: payment.userEmail,
            }
          )}
        </span>
        <div className={clsx(styles.date, 'body-2')}>
          {formatDate({ date: payment.createdAt, locale, token: 'date-year-s' })}
        </div>
      </div>
      <MenuTrigger>
        <AriaButton
          aria-hidden="true"
          aria-label={formatMessage({
            id: 'payment-links.sidebar.related-payments.context-menu.create-invoice',
          })}
          className={styles.trigger}
          data-testid="trigger"
        >
          <IconDotsOutlined />
        </AriaButton>
        <Popover
          UNSTABLE_portalContainer={document.getElementById('ember-testing') ?? document.body}
          className={styles.popover}
          maxHeight={200}
          offset={-4}
          placement="bottom right"
        >
          <Menu>
            <MenuItem
              className={clsx('body-1', styles['list-item'])}
              data-test-create-invoice
              onAction={() => {
                if (onClickCreateInvoice) {
                  onClickCreateInvoice();
                }
              }}
            >
              <IconShortcutCreateInvoiceOutlined />
              <Tooltip
                label={formatMessage({
                  id: 'payment-links.sidebar.related-payments.context-menu.coming-soon',
                })}
                needsButton
                offset={24}
                placement="top left"
              >
                <span>
                  {formatMessage({
                    id: 'payment-links.sidebar.related-payments.context-menu.create-invoice',
                  })}
                </span>
              </Tooltip>
            </MenuItem>
          </Menu>
        </Popover>
      </MenuTrigger>
    </div>
  );
}
