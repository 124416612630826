import { Button, Flag, Select, SelectOption, type CountryCode } from '@repo/design-system-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { valibotResolver } from '@hookform/resolvers/valibot';
import * as v from 'valibot';
import styles from './account-iban-download.strict-module.css';

export interface IbanFormValues {
  locale: string;
}

export interface AccountIbanDownloadProps {
  locales: string[];
  isLoading?: boolean;
  onSubmit: (values: IbanFormValues) => void;
  onCopyIban: () => void;
}

const localeToCountryCode = (locale: string): string => {
  if (locale === 'en') {
    return 'GB';
  }
  return locale.toUpperCase();
};

export function AccountIbanDownload({
  locales,
  isLoading = false,
  onSubmit,
  onCopyIban,
}: AccountIbanDownloadProps): React.ReactNode {
  const { formatMessage } = useIntl();

  const formSchema = v.object({
    locale: v.picklist(
      locales,
      formatMessage({ id: 'bank_accounts.certificate.error-disclaimer' })
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IbanFormValues>({
    resolver: valibotResolver(formSchema),
    defaultValues: {
      locale: '',
    },
  });

  const getLocaleName = (localeCode: string): string => {
    return formatMessage({ id: `bank_accounts.share.download.link-${localeCode}` });
  };

  return (
    <div className={styles.wrapper} data-testid="iban-download">
      <form
        onSubmit={async e => {
          e.preventDefault();
          await handleSubmit(onSubmit)();
        }}
      >
        <div className={styles.selectContainer}>
          <Controller
            control={control}
            name="locale"
            render={({ field: { ref: _, ...field } }) => (
              <Select
                className={styles.select}
                data-testid="iban-language-dropdown"
                errorMessage={errors.locale?.message}
                label={formatMessage({
                  id: 'bank_accounts.download.selector-title',
                })}
                onSelectionChange={field.onChange}
                placeholder={formatMessage({
                  id: 'bank_accounts.download.selector-placeholder',
                })}
                selectedKey={field.value}
                {...field}
              >
                {locales.map(locale => (
                  <SelectOption
                    data-testid={`iban-language-option-${locale}`}
                    id={locale}
                    key={locale}
                  >
                    <div className={styles.optionContent}>
                      <Flag
                        className={styles.flagIcon}
                        code={localeToCountryCode(locale) as CountryCode}
                        size="small"
                        variant="square"
                      />
                      <span className={styles.optionText}>{getLocaleName(locale)}</span>
                    </div>
                  </SelectOption>
                ))}
              </Select>
            )}
          />
        </div>

        <div className={styles.actionsContainer}>
          <Button
            data-testid="download-iban-button"
            isLoading={isLoading}
            type="submit"
            variant="primary"
          >
            <FormattedMessage id="bank_accounts.share.download.cta" />
          </Button>

          <span className={styles.orSeparator}>
            <FormattedMessage id="bank_accounts.iban.cta-or" />
          </span>

          <Button
            data-testid="copy-iban-button"
            onPress={onCopyIban}
            type="button"
            variant="tertiary"
          >
            <FormattedMessage id="bank_accounts.iban.copy-cta" />
          </Button>
        </div>
      </form>
    </div>
  );
}
