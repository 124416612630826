import type { HTMLProps, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { PLACEHOLDER_SECTIONS } from '../../../constants/pdf-preview.ts';
import type { Document, MerchantDetails } from '../../types';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import { Placeholder } from '../../placeholders';
import styles from './footer-details.strict-module.css';

type FooterDetailsProps = FooterMerchantDetailsProps &
  FooterCompanyDetailsProps &
  FooterPaymentDetailsProps;

export function FooterDetails({ document, merchant, contactEmail }: FooterDetailsProps): ReactNode {
  return (
    <div className={styles.container}>
      <FooterMerchantDetails contactEmail={contactEmail} merchant={merchant} />
      <FooterCompanyDetails merchant={merchant} />
      <FooterPaymentDetails document={document} />
    </div>
  );
}

interface FooterMerchantDetailsProps {
  merchant: MerchantDetails;
  contactEmail?: string;
}

function FooterMerchantDetails({ merchant, contactEmail }: FooterMerchantDetailsProps): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage } = usePdfPreviewContext();

  const dotSeparator = '\u00A0\u00B7\u00A0';

  const merchantAddress = `${merchant.address.zipCode} ${merchant.address.city}, ${merchant.address.countryCode}`;

  return (
    <Details data-testid="pdf-preview-de-footer-merchant-info">
      <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="105px">
        <Detail data-testid="pdf-preview-de-footer-merchant-footer-name">
          {merchant.legalName}
        </Detail>
      </Placeholder>
      <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="67px">
        <Detail data-testid="pdf-preview-de-footer-merchant-footer-street">
          {merchant.address.streetAddress}
        </Detail>
      </Placeholder>
      <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="62px">
        <Detail data-testid="pdf-preview-de-footer-merchant-footer-address">
          {merchantAddress}
        </Detail>
      </Placeholder>
      <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="83px">
        <Detail data-testid="pdf-preview-de-footer-email">{contactEmail}</Detail>
      </Placeholder>
      <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="120px">
        <Detail>
          <span>
            {merchant.districtCourt ? (
              <>
                {formatMessage(
                  {
                    id: 'pdf-preview.district-court',
                  },
                  { language: displayLanguage }
                )}{' '}
                <span data-testid="pdf-preview-de-footer-district-court">
                  {merchant.districtCourt}
                </span>
              </>
            ) : null}
            {merchant.districtCourt && merchant.commercialRegisterNumber ? dotSeparator : null}
            {merchant.commercialRegisterNumber ? (
              <span data-testid="pdf-preview-de-footer-commercial-number">
                {merchant.commercialRegisterNumber}
              </span>
            ) : null}
          </span>
        </Detail>
      </Placeholder>
    </Details>
  );
}

interface FooterCompanyDetailsProps {
  merchant: MerchantDetails;
}

function FooterCompanyDetails({ merchant }: FooterCompanyDetailsProps): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage } = usePdfPreviewContext();

  const showCompanyDetails = merchant.companyLeadership || merchant.taxNumber || merchant.vatNumber;

  if (showCompanyDetails) {
    const companyLeadershipDetail = `${formatMessage({ id: 'pdf-preview.leadership' }, { language: displayLanguage })} ${merchant.companyLeadership}`;
    const taxNumberDetail = `${formatMessage({ id: 'pdf-preview.steuernummer' }, { language: displayLanguage })} ${merchant.taxNumber}`;
    const vatNumberDetail = `${formatMessage({ id: 'pdf-preview.ust-udnr' }, { language: displayLanguage })} ${merchant.vatNumber}`;
    return (
      <Details>
        {merchant.companyLeadership ? (
          <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="110px">
            <Detail data-testid="pdf-preview-de-footer-merchant-leadership">
              {companyLeadershipDetail}
            </Detail>
          </Placeholder>
        ) : null}
        {merchant.taxNumber ? (
          <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="115px">
            <Detail data-testid="pdf-preview-de-footer-merchant-tax-number">
              {taxNumberDetail}
            </Detail>
          </Placeholder>
        ) : null}
        {merchant.vatNumber ? (
          <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="90px">
            <Detail data-testid="pdf-preview-de-footer-merchant-vat-number">
              {vatNumberDetail}
            </Detail>
          </Placeholder>
        ) : null}
      </Details>
    );
  }

  return null;
}

interface FooterPaymentDetailsProps {
  document: Document;
}

function FooterPaymentDetails({ document }: FooterPaymentDetailsProps): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage, type } = usePdfPreviewContext();

  if (type === 'invoice') {
    const isForeignCurrency = document.currency && document.currency !== 'EUR';

    const ibanDetail = `${formatMessage({ id: 'pdf-preview.iban' }, { language: displayLanguage })}: ${document.iban}`;
    const bicDetail = `${formatMessage({ id: 'pdf-preview.bic' }, { language: displayLanguage })}: ${document.bic}`;
    const intermediaryBicDetail = `${formatMessage({ id: 'pdf-preview.intermediary-bic.label' }, { language: displayLanguage })}: ${formatMessage({ id: 'pdf-preview.intermediary-bic.value' }, { language: displayLanguage })}`;
    const referenceDetail = `${formatMessage({ id: 'pdf-preview.reference' }, { language: displayLanguage })}: ${document.reference}`;

    return (
      <Details data-testid="pdf-preview-de-footer-payment-details">
        <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="100px">
          <Detail data-testid="pdf-preview-de-footer-payment-details-beneficiary">
            {document.beneficiaryName}
          </Detail>
        </Placeholder>
        <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="140px">
          <Detail data-testid="pdf-preview-de-footer-payment-details-iban">{ibanDetail}</Detail>
        </Placeholder>
        {document.bic ? (
          <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="70px">
            <Detail data-testid="pdf-preview-de-footer-payment-details-bic">{bicDetail}</Detail>
          </Placeholder>
        ) : null}
        {isForeignCurrency ? (
          <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="110px">
            <Detail data-testid="pdf-preview-de-footer-payment-details-intermediary-bic">
              {intermediaryBicDetail}
            </Detail>
          </Placeholder>
        ) : null}
        <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="85px">
          <Detail data-testid="pdf-preview-de-footer-payment-details-reference">
            {referenceDetail}
          </Detail>
        </Placeholder>
      </Details>
    );
  }

  return null;
}

function Details(props: HTMLProps<HTMLParagraphElement>): ReactNode {
  return <p {...props} className={styles.details} />;
}

function Detail(props: HTMLProps<HTMLParagraphElement>): ReactNode {
  return <p {...props} className="body-3" />;
}
