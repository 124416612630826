import type { PropsWithChildren } from 'react';
import { usePdfPreviewContext } from '../pdf-preview-context.tsx';
import { PLACEHOLDER_SECTIONS } from '../../constants/pdf-preview.ts';
import { Placeholder } from './internals/placeholder';
import styles from './table-placeholder.strict-module.css';

export function TablePlaceholder({ children }: PropsWithChildren): React.ReactNode {
  const { placeholders, placeholdersOnly } = usePdfPreviewContext();

  if (placeholdersOnly || placeholders?.includes(PLACEHOLDER_SECTIONS.ITEMS_TABLE)) {
    return (
      <div className={styles.table} data-testid="items-table-placeholder">
        <Placeholder className={styles['mb-22']} width="100%" />
        <div className={styles.row} data-testid="items-table-placeholder-item-row">
          <div className={styles.labels}>
            <Placeholder width="153px" />
            <Placeholder width="105px" />
          </div>
          <div className={styles.details} data-testid="items-table-placeholder-item-row-details">
            <Placeholder className={styles['ml-4']} width="20px" />
            <Placeholder className={styles['ml-5']} width="55px" />
            <Placeholder className={styles['ml-5']} width="32px" />
            <Placeholder className={styles['ml-15']} width="66px" />
          </div>
        </div>
        <div className={styles.totals} data-testid="items-table-placeholder-totals">
          <div className={styles.total}>
            <Placeholder width="77px" />
            <Placeholder width="43px" />
          </div>
          <div className={styles.total}>
            <Placeholder width="64px" />
            <Placeholder width="35px" />
          </div>
          <div className={styles.total}>
            <Placeholder width="71px" />
            <Placeholder width="53px" />
          </div>
        </div>
      </div>
    );
  }

  return <>{children}</>;
}
