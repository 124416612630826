import type { HTMLProps, PropsWithChildren, ReactNode } from 'react';
import { clsx } from 'clsx';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import { Placeholder } from '../../placeholders';
import type { PlaceholderSection } from '../../types';
import styles from './details.strict-module.css';

export function Details({ children }: PropsWithChildren): ReactNode {
  return <div className={styles.details}>{children}</div>;
}

interface DetailProps extends Omit<HTMLProps<HTMLParagraphElement>, 'value'> {
  placeholderSections?: PlaceholderSection[];
  placeholderWidths?: [string, string];
  label: string;
  value?: ReactNode;
}

export function Detail({
  placeholderSections,
  placeholderWidths,
  label,
  value,
  ...props
}: DetailProps): ReactNode {
  const { variant } = usePdfPreviewContext();

  return (
    <p className={styles.detail} {...props}>
      <span className={clsx('body-1-bold', styles.label, styles[variant])}>
        <Placeholder section={placeholderSections?.[0]} width={placeholderWidths?.[0]}>
          {label}
        </Placeholder>
      </span>
      <span className="body-1">
        <Placeholder section={placeholderSections?.[1]} width={placeholderWidths?.[1]}>
          {value}
        </Placeholder>
      </span>
    </p>
  );
}
