import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import type { ClientDetails as ClientDetailsType } from '../../types';
import { PLACEHOLDER_SECTIONS } from '../../../constants/pdf-preview.ts';
import { Detail, Details } from './details.tsx';

interface ClientDetailsProps {
  client?: ClientDetailsType;
}

export function ClientDetails({ client }: ClientDetailsProps): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage } = usePdfPreviewContext();

  if (client?.vatNumber || client?.tinNumber) {
    return (
      <Details>
        {client.vatNumber ? (
          <Detail
            data-testid="pdf-preview-client-vat-number"
            label={formatMessage(
              { id: 'pdf-preview.client-ust-udnr' },
              { language: displayLanguage }
            )}
            placeholderSections={[
              PLACEHOLDER_SECTIONS.DE_CUSTOMER_VAT_NUMBER,
              PLACEHOLDER_SECTIONS.DE_CUSTOMER_VAT_NUMBER_VALUE,
            ]}
            placeholderWidths={['69px', '62px']}
            value={client.vatNumber}
          />
        ) : null}
        {client.tinNumber ? (
          <Detail
            data-testid="pdf-preview-client-tin-number"
            label={formatMessage(
              { id: 'pdf-preview.client-steuer-id' },
              { language: displayLanguage }
            )}
            placeholderSections={[
              PLACEHOLDER_SECTIONS.DE_CUSTOMER_TIN_NUMBER,
              PLACEHOLDER_SECTIONS.DE_CUSTOMER_TIN_NUMBER_VALUE,
            ]}
            placeholderWidths={['69px', '71px']}
            value={client.tinNumber}
          />
        ) : null}
      </Details>
    );
  }

  return null;
}
