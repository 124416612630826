import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { MerchantDetails } from '../../types';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import styles from './terms-and-conditions.strict-module.css';

interface TermsAndConditionsProps {
  merchant: MerchantDetails;
  termsAndConditions?: string;
}

export function TermsAndConditions({
  merchant,
  termsAndConditions,
}: TermsAndConditionsProps): ReactNode {
  const { formatMessage, formatNumber } = useIntl();
  const { displayLanguage, settings, variant } = usePdfPreviewContext();

  const { legalCapitalShare, commercialRegisterNumber } = settings ?? {};

  // currency will always be EUR as it's the default merchant's legal country currency
  const formattedCapitalShare = legalCapitalShare
    ? formatNumber(legalCapitalShare, {
        currency: 'EUR',
        style: 'currency',
      })
    : null;

  const termsAndConditionsContent = useMemo(() => {
    let orgNameForm = merchant.legalName;

    if (
      merchant.shortLegalForm &&
      !new RegExp(` ${merchant.shortLegalForm.trim()}$`, 'i').test(orgNameForm.trim())
    ) {
      orgNameForm = `${orgNameForm}, ${merchant.shortLegalForm}`;
    }

    // FR has 2 specific field to display: rsc number and capital share if available
    if (variant === 'FR') {
      if (formattedCapitalShare) {
        orgNameForm = formatMessage(
          { id: 'pdf-preview.footer-organization' },
          {
            orgaName: orgNameForm,
            amount: formattedCapitalShare,
            language: displayLanguage,
          }
        );
      }

      if (commercialRegisterNumber) {
        orgNameForm = `${orgNameForm} · ${commercialRegisterNumber}`;
      }
    }

    return termsAndConditions ? `${orgNameForm} · ${termsAndConditions}` : orgNameForm;
  }, [
    displayLanguage,
    formatMessage,
    formattedCapitalShare,
    merchant.legalName,
    merchant.shortLegalForm,
    commercialRegisterNumber,
    termsAndConditions,
    variant,
  ]);

  const textSizeClassName = useMemo(() => {
    if (termsAndConditionsContent.length <= 375) return '';
    if (termsAndConditionsContent.length <= 425) return styles['terms-conditions-size-9'] ?? '';
    return styles['terms-conditions-size-8'] ?? '';
  }, [termsAndConditionsContent]);

  return (
    <div className={styles.container}>
      <p
        className={clsx('body-1', styles['terms-conditions'], textSizeClassName)}
        data-testid="pdf-preview-terms-and-conditions"
      >
        {termsAndConditionsContent}
      </p>
    </div>
  );
}
