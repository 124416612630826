import type { LocaleCode } from '@repo/shared-config/app/constants/locales';
import { DEFAULT_LOCALE, LOCALE_CODES } from '@repo/shared-config/app/constants/locales';

function isValidLocaleCode(code: string): code is LocaleCode {
  return LOCALE_CODES.includes(code as LocaleCode);
}

export function detectLocale(): LocaleCode {
  const htmlTag = document.querySelector('html');
  if (htmlTag) {
    const lang = htmlTag.getAttribute('lang');
    if (lang && isValidLocaleCode(lang)) return lang as LocaleCode;
  }

  if (isValidLocaleCode(navigator.language)) {
    return navigator.language;
  }

  return DEFAULT_LOCALE;
}
