import type { PropsWithChildren } from 'react';
import type { PlaceholderSection } from '../types';
import { usePdfPreviewContext } from '../pdf-preview-context.tsx';
import {
  Placeholder as PlaceholderComponent,
  type PlaceholderProps as PlaceholderComponentProps,
} from './internals/placeholder.tsx';

interface PlaceholderProps extends PlaceholderComponentProps {
  section?: PlaceholderSection;
}

export function Placeholder({
  section,
  children,
  ...props
}: PropsWithChildren<PlaceholderProps>): React.ReactNode {
  const { placeholders, placeholdersOnly } = usePdfPreviewContext();

  if (section && (placeholders?.includes(section) || placeholdersOnly)) {
    return <PlaceholderComponent {...props} />;
  }

  return <>{children}</>;
}
