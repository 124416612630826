import { Popup } from '@repo/design-system-kit';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NoPairedDeviceSvg } from '../../../svgs/no-paired-device';
import { faqService } from '../../../utils/faq-composer';
import styles from './sca.strict-module.css';

interface NoPairedDeviceProps {
  onClose?: () => void;
}
export function NoPairedDevice({ onClose }: NoPairedDeviceProps): React.ReactNode {
  // NOTE -- this is needed until we have a better way to handle the portal container in qunit tests
  // https://www.notion.so/qonto/React-popover-library-used-within-react-aria-components-does-mount-popovers-modals-outside-of-qunit--12631ee4c696806e87dbef81e9a27922
  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;

  const [isOpen, setIsOpen] = useState(true);
  const { formatMessage } = useIntl();
  const handleClose = (): void => {
    onClose?.();
    setIsOpen(false);
  };

  return (
    <Popup.Root
      aria-label={formatMessage({ id: 'sca.no-paired-device.title' })}
      data-testid="sca-no-paired-device"
      isOpen={isOpen}
      portalContainer={TEST_PORTAL_CONTAINER}
      role="dialog"
    >
      <Popup.CloseButton onPress={handleClose} />
      <Popup.Header>
        <figure className={styles.illustrationWrap}>
          <NoPairedDeviceSvg />
        </figure>
        <Popup.Title>
          <FormattedMessage id="sca.no-paired-device.title" />
        </Popup.Title>
        <Popup.Subtitle>
          <FormattedMessage id="sca.no-paired-device.subtitle" />
        </Popup.Subtitle>
      </Popup.Header>
      <Popup.Body>
        <a
          className={styles.faqLink}
          href={faqService.getLocalizedArticle(4674606)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage id="sca.no-paired-device.info" />
        </a>
      </Popup.Body>
    </Popup.Root>
  );
}
