import cx from 'clsx';
import type { ReactNode } from 'react';
import type { Logo as LogoType } from '../../types';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import styles from './logo.strict-module.css';

interface LogoProps {
  logo?: LogoType;
}

export function Logo({ logo }: LogoProps): ReactNode {
  const { variant } = usePdfPreviewContext();

  if (logo) {
    return (
      <div className={cx(styles.logo, styles[variant])}>
        <img alt="" data-testid="pdf-preview-logo" role="none" src={logo.fileUrl} />
      </div>
    );
  }

  return null;
}
