import type { ReactNode } from 'react';
import type { Document, MerchantDetails } from '../types';
import { DocumentConditions } from './internals/document-conditions.tsx';
import { TermsAndConditionsLink } from './internals/terms-and-conditions-link.tsx';
import { VatExemptions } from './internals/vat-exemptions.tsx';

interface ConditionsProps {
  document: Document;
  merchant: MerchantDetails;
}

export function Conditions({ document, merchant }: ConditionsProps): ReactNode {
  return (
    <div>
      <VatExemptions legalCountry={merchant.legalCountry} sections={document.sections} />
      <DocumentConditions />
      <TermsAndConditionsLink />
    </div>
  );
}
