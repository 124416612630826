import { type ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { clsx } from 'clsx';
import { UNIT_CODES } from '../../../../constants';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import type { Item } from '../../types';
import { useTranslatePlaceholders } from '../../../hooks/use-translate-placeholders.ts';
import { usePdfPreviewColumns } from './use-pdf-preview-columns.ts';
import styles from './item-row.strict-module.css';

interface ItemRowProps {
  item: Item;
  index: number;
  hideBorder?: boolean;
}

export function ItemRow({ hideBorder, item, index }: ItemRowProps): ReactNode {
  const { columns } = usePdfPreviewColumns();

  return (
    <tr
      className={clsx(styles.row, hideBorder && styles['no-bottom'])}
      data-testid={`pdf-preview-items-row-${index}`}
    >
      {columns.map(column => (
        <ItemCell column={column.id} item={item} key={column.id} />
      ))}
    </tr>
  );
}

interface ItemCellProps {
  column: string;
  item: Item;
}

function ItemCell({ column, item }: ItemCellProps): ReactNode {
  if (column === 'description') {
    return <ItemDescriptionCell description={item.description} title={item.title} />;
  }

  if (column === 'quantity') {
    return <ItemQuantityCell quantity={item.quantity} unit={item.unit} />;
  }

  if (column === 'unit_price') {
    return <ItemUnitPriceCell unitPrice={item.unitPrice} />;
  }

  if (column === 'vat_rate') {
    return <ItemVatRateCell vatRate={item.vatRate} />;
  }

  if (column === 'total' || column === 'subtotal') {
    return <ItemTotalCell totalExcludingVat={item.totalExcludingVat} />;
  }

  return <td />;
}

interface ItemDescriptionCellProps {
  title?: string;
  description?: string;
}

function ItemDescriptionCell({ title, description }: ItemDescriptionCellProps): ReactNode {
  const { displayLanguage } = usePdfPreviewContext();
  const { translatePlaceholders } = useTranslatePlaceholders();

  return (
    <td className="body-1">
      <p className={styles.title} data-testid="pdf-preview-item-title">
        {title ? translatePlaceholders(title, displayLanguage) : null}
      </p>
      {description ? (
        <p className={styles.description} data-testid="pdf-preview-item-description">
          {translatePlaceholders(description, displayLanguage)}
        </p>
      ) : null}
    </td>
  );
}

interface ItemQuantityCellProps {
  quantity?: string;
  unit?: string;
}

function ItemQuantityCell({ quantity, unit }: ItemQuantityCellProps): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage } = usePdfPreviewContext();

  const formattedUnit = useMemo(() => {
    let qty = Math.abs(Number(quantity));
    if (displayLanguage === 'fr') {
      qty = qty > -2 && qty < 2 ? 1 : qty;
    }

    if (unit && UNIT_CODES.includes(unit)) {
      return ` ${formatMessage(
        { id: `pdf-preview.unit.${unit}` },
        { number: qty, language: displayLanguage }
      )}`;
    }
    return unit ? ` ${unit}` : '';
  }, [displayLanguage, formatMessage, quantity, unit]);

  return (
    <td className="body-1" data-testid="pdf-preview-item-quantity">
      {quantity} {formattedUnit}
    </td>
  );
}

interface ItemUnitPriceCellProps {
  unitPrice?: string;
}

function ItemUnitPriceCell({ unitPrice }: ItemUnitPriceCellProps): ReactNode {
  const { formatNumber } = useIntl();
  const { currency } = usePdfPreviewContext();

  return (
    <td className="body-1" data-testid="pdf-preview-item-unit-price">
      {unitPrice
        ? formatNumber(Number(unitPrice), {
            style: 'currency',
            currency,
            minimumFractionDigits: 2,
          })
        : null}
    </td>
  );
}

interface ItemVatRateCellProps {
  vatRate?: string;
}

function ItemVatRateCell({ vatRate }: ItemVatRateCellProps): ReactNode {
  const { formatNumber } = useIntl();

  return (
    <td className="body-1" data-testid="pdf-preview-item-vat-rate">
      {vatRate
        ? formatNumber(Number(vatRate), {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          })
        : null}
    </td>
  );
}

interface ItemTotalCellProps {
  totalExcludingVat?: string;
}

function ItemTotalCell({ totalExcludingVat }: ItemTotalCellProps): ReactNode {
  const { formatNumber } = useIntl();
  const { currency } = usePdfPreviewContext();

  return (
    <td className="body-1" data-testid="pdf-preview-item-total-amount">
      {totalExcludingVat
        ? formatNumber(Number(totalExcludingVat), {
            style: 'currency',
            currency,
            minimumFractionDigits: 2,
          })
        : null}
    </td>
  );
}
