export enum PaymentLinkStatus {
  Paid = 'paid',
  Open = 'open',
  Expired = 'expired',
  Canceled = 'canceled',
}

export type PaymentLinkStatusType = (typeof PaymentLinkStatus)[keyof typeof PaymentLinkStatus];

export enum PaymentLinkResourceType {
  Invoice = 'Invoice',
  Basket = 'Basket',
}

export type PaymentLinkResourceTypeType =
  (typeof PaymentLinkResourceType)[keyof typeof PaymentLinkResourceType];
