import type { ReactElement, ReactNode } from 'react';
import type {
  TooltipProps as AriaTooltipProps,
  TooltipTriggerComponentProps,
} from 'react-aria-components';
import { Tooltip as TooltipComponent, TooltipTrigger } from 'react-aria-components';
import cx from 'clsx';
import { Button, type ButtonProps } from '../button/button';
import styles from './tooltip.strict-module.css';

export interface TooltipProps extends TooltipTriggerComponentProps {
  children: ReactNode;
  label: string | ReactElement | ReactNode;
  placement?: AriaTooltipProps['placement'];
  className?: string;
  tooltipTestId?: string; // Custom test ID for the tooltip
  needsButton?: boolean;
  isDisabled?: boolean;
  buttonProps?: Omit<ButtonProps, 'children'>;
  offset?: number;
}

export function Tooltip({
  children,
  label,
  placement = 'bottom',
  delay = 0,
  closeDelay = 0,
  className,
  tooltipTestId,
  needsButton = false,
  isDisabled = false,
  offset = 8,
  buttonProps,
  ...triggerProps
}: TooltipProps): JSX.Element {
  return (
    <TooltipTrigger
      closeDelay={closeDelay}
      data-test-tooltip
      data-testid="tooltip"
      delay={delay}
      isDisabled={isDisabled}
      {...triggerProps}
    >
      {needsButton ? (
        <Button
          className={styles.button}
          data-test-tooltip-button={tooltipTestId}
          data-tooltip-wrapper={tooltipTestId}
          {...buttonProps}
        >
          {children}
        </Button>
      ) : (
        children
      )}
      <TooltipComponent
        className={cx(styles.content, 'body-2', className)}
        data-test-tooltip-message
        data-testid={tooltipTestId}
        offset={offset}
        placement={placement}
      >
        {label}
      </TooltipComponent>
    </TooltipTrigger>
  );
}
