const SCA_ERROR_CODES = [428, 412];

export const httpClient = {
  get: async <T>(
    url: string,
    options?: {
      headers?: Record<string, string>;
      params?: Record<string, string | number | boolean>;
    }
  ): Promise<T> => {
    const queryString = options?.params
      ? new URLSearchParams(
          Object.entries(options.params).map(([k, v]) => [k, String(v)])
        ).toString()
      : '';
    const fullUrl = queryString ? `${url}?${queryString}` : url;
    const response = await fetch(fullUrl, {
      method: 'GET',
      headers: options?.headers,
    });

    const contentType = response.headers.get('content-type');
    if (response.status === 204 || !contentType || !contentType.includes('application/json')) {
      return {} as T;
    }

    if (!response.ok && !SCA_ERROR_CODES.includes(response.status)) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return (await response.json()) as T;
  },

  post: async <T, D>(
    url: string,
    { body, headers, ...options }: Omit<RequestInit, 'method' | 'body'> & { body?: D }
  ): Promise<T> => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(body),
      ...options,
    });

    const contentType = response.headers.get('content-type');

    if (response.status === 204 || !contentType || !contentType.includes('application/json')) {
      return {} as T;
    }

    if (!response.ok && !SCA_ERROR_CODES.includes(response.status)) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return (await response.json()) as T;
  },
};
