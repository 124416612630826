import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import type { Link } from '../../types';
import styles from './item-links-row.strict-module.css';

interface ItemLinksRowProps {
  links?: Link[];
}

export function ItemLinksRow({ links }: ItemLinksRowProps): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage } = usePdfPreviewContext();

  if (links?.length) {
    return (
      <tr className={styles.links} data-testid="pdf-preview-items-row-links">
        <td className="body-1" colSpan={4}>
          <p className={styles.description} data-testid="pdf-preview-items-row-link-label">
            {formatMessage(
              { id: 'pdf-preview.items-links' },
              { number: links.length, language: displayLanguage }
            )}
          </p>
          <ul>
            {links.map((link, linkIndex) => (
              // eslint-disable-next-line react/no-array-index-key -- There is no unique identifier so we have to use index
              <li key={linkIndex}>
                <a
                  className="body-1"
                  data-testid={`pdf-preview-items-row-link-${linkIndex}`}
                  href={link.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        </td>
      </tr>
    );
  }

  return null;
}
