import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import styles from './page-number.strict-module.css';

export function PageNumber(): ReactNode {
  const { pageCount } = usePdfPreviewContext();

  if (pageCount) {
    return (
      <p
        className={clsx('body-3', styles['page-number'])}
        data-testid="pdf-preview-de-footer-page-count"
      >
        {pageCount}
      </p>
    );
  }

  return null;
}
