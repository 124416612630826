import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import { usePdfPreviewColumns } from './use-pdf-preview-columns.ts';
import styles from './item-header.strict-module.css';

export function ItemHeader(): ReactNode {
  const { variant } = usePdfPreviewContext();
  const { columns } = usePdfPreviewColumns();

  const columnClasses: Record<string, string | undefined> = {
    description: variant === 'DE' ? styles['description-de'] : styles.description,
    quantity: styles.quantity,
    unit_price: styles.amount,
    vat_rate: styles.vat,
    subtotal: styles.subtotal,
  };

  return (
    <thead className={styles.thead}>
      <tr>
        {columns.map((column, index) => (
          <th
            className={clsx('body-1-bold', columnClasses[column.id])}
            data-testid={`pdf-preview-column-${column.id}`}
            data-testid-order={index}
            key={column.id}
          >
            {column.value}
          </th>
        ))}
      </tr>
    </thead>
  );
}
