import type { ReactElement, SVGProps } from 'react';

export function IconEmptystateUpgrade(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          clipRule="evenodd"
          d="M3.655 6.744V4.52c0-2.497 2.01-4.52 4.49-4.52 2.48 0 4.49 2.023 4.49 4.519v2.225h1.122a.79.79 0 0 1 .788.793v7.67a.79.79 0 0 1-.788.793H2.532a.789.789 0 0 1-.787-.792V7.537a.79.79 0 0 1 .787-.793h1.123Zm6.735 0V4.52a2.252 2.252 0 0 0-2.245-2.26A2.252 2.252 0 0 0 5.9 4.52v2.225h4.49Zm-2.244 5.952a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Z"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
