import { useEffect, useState } from 'react';
import { emailVerificationChannel } from '../utils/broadcast-channel';
import {
  EmailVerificationModal,
  EMAIL_VERIFICATION_STATUSES,
  type EmailVerificationStatus,
} from '../components';
import { calculateVerificationTimeout } from '../utils/calculate-time-diff';
import type { ErrorComponentProps } from './sensitive-action';


export function EmailVerificationUI({
  onClose,
  errorDetails,
  onSensitiveAction,
}: ErrorComponentProps): JSX.Element {
  const [emailVerificationStatus, setEmailVerificationStatus] = useState<EmailVerificationStatus>(
    EMAIL_VERIFICATION_STATUSES.EMAIL_VERIFICATION_REQUIRED
  );

  const deviceVerificationEmail = calculateVerificationTimeout(
    errorDetails.deviceVerificationEmail
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setEmailVerificationStatus(EMAIL_VERIFICATION_STATUSES.EMAIL_VERIFICATION_TIMEOUT);
    }, deviceVerificationEmail);

    const cleanupListener = emailVerificationChannel.onMessage(async ({ action }) => {
      switch (action) {
        case 'confirm-device-success':
          await onSensitiveAction();
          break;
        case 'confirm-device-error':
          setEmailVerificationStatus(EMAIL_VERIFICATION_STATUSES.ERROR);
          break;
      }
      clearTimeout(timeout);
    });

    return () => {
      cleanupListener();
      clearTimeout(timeout);
    };
  }, [onSensitiveAction, deviceVerificationEmail]);

  return <EmailVerificationModal onClose={onClose} status={emailVerificationStatus} />;
}
