import { useIntl } from 'react-intl';
import { DEFAULT_COLUMNS } from '../../../constants/pdf-preview.ts';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';

interface UsePdfPreviewColumnsReturnValue {
  columns: { id: string; value: string }[];
}

export function usePdfPreviewColumns(): UsePdfPreviewColumnsReturnValue {
  const { formatMessage } = useIntl();
  const { displayLanguage, settings } = usePdfPreviewContext();

  const defaultTableColumnLabels = {
    description: formatMessage({ id: 'pdf-preview.items' }, { language: displayLanguage }),
    quantity: formatMessage({ id: 'pdf-preview.quantity' }, { language: displayLanguage }),
    unit_price: formatMessage({ id: 'pdf-preview.unit-price' }, { language: displayLanguage }),
    vat_rate: formatMessage({ id: 'pdf-preview.vat' }, { language: displayLanguage }),
    subtotal: formatMessage({ id: 'pdf-preview.total-without-vat' }, { language: displayLanguage }),
  };

  const columns = (settings?.tableColumns ?? DEFAULT_COLUMNS).map(({ id, label }) => ({
    id,
    value: label || defaultTableColumnLabels[id as keyof typeof defaultTableColumnLabels],
  }));

  return { columns };
}
