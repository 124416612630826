import { LOCALE_CODES, type LocaleCode } from '@repo/shared-config/app/constants/locales';

export const FORMAT_OPTIONS = {
  compact: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
  'date-s': { month: 'short', day: '2-digit' },
  'month-year-l': { month: 'long', year: 'numeric' },
  'month-year-s': { month: 'short', year: 'numeric' },
  'date-year-s': { day: '2-digit', month: 'short', year: 'numeric' },
  'date-year-l': { day: '2-digit', month: 'long', year: 'numeric' },
  'week-date-l': { month: 'long', weekday: 'long', day: '2-digit' },
  'date-time-l': {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  },
  'date-time-s': {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  },
} as const;

const LOCALE_CUSTOM_FORMATS: Record<
  LocaleCode,
  Partial<Record<keyof typeof FORMAT_OPTIONS, (parts: Intl.DateTimeFormatPart[]) => string>>
> = {
  en: {
    compact: ([month, , day, , year]) => `${day?.value}-${month?.value}-${year?.value}`,
    'date-time-l': parts => parts.map(({ value }, i) => (i === 5 ? ', ' : value)).join(''),
    'date-time-s': parts => parts.map(({ value }, i) => (i === 5 ? ', ' : value)).join(''),
  },
  fr: {
    'date-time-l': parts => parts.map(({ value }, i) => (i === 5 ? ', ' : value)).join(''),
    'date-time-s': parts => parts.map(({ value }, i) => (i === 5 ? ', ' : value)).join(''),
  },
  de: {
    'date-time-l': parts =>
      parts.map(({ value }, i) => ([3, 5].includes(i) ? ', ' : value)).join(''),
    'date-time-s': parts => parts.map(({ value }, i) => (i === 5 ? ', ' : value)).join(''),
  },
  it: {
    'date-time-l': parts => parts.map(({ value }, i) => (i === 5 ? ', ' : value)).join(''),
    'date-time-s': parts => parts.map(({ value }, i) => (i === 5 ? ', ' : value)).join(''),
  },
  es: {
    compact: parts => parts.map(({ type, value }) => (type === 'literal' ? '-' : value)).join(''),
    'week-date-l': parts => parts.map(({ value }, i) => (i === 1 ? ' ' : value)).join(''),
  },
  pt: {
    'date-s': parts =>
      parts
        .map(({ value, type }) => {
          if (type === 'literal') {
            return ' ';
          } else if (type === 'month' && value.endsWith('.')) {
            return value.slice(0, -1);
          }
          return value;
        })
        .join(''),
    'month-year-s': parts =>
      parts
        .map(({ value, type }) => {
          if (type === 'literal') {
            return ' ';
          } else if (type === 'month' && value.endsWith('.')) {
            return value.slice(0, -1);
          }
          return value;
        })
        .join(''),
    'date-year-s': parts =>
      parts
        .map(({ value, type }) => {
          if (type === 'literal') {
            return ' ';
          } else if (type === 'month' && value.endsWith('.')) {
            return value.slice(0, -1);
          }
          return value;
        })
        .join(''),
    'date-time-l': parts =>
      parts
        .map(({ value, type }) => (type === 'literal' && value === ' às ' ? ', ' : value))
        .join(''),
    'date-time-s': parts =>
      parts
        .map(({ value, type }) => {
          if (type === 'literal' && value === ' às ') {
            return ', ';
          } else if (type === 'literal' && value === ' de ') {
            return ' ';
          } else if (type === 'month' && value.endsWith('.')) {
            return value.slice(0, -1);
          }
          return value;
        })
        .join(''),
  },
} as const;

const isLocaleCode = (locale: string): locale is LocaleCode => {
  return LOCALE_CODES.includes(locale as LocaleCode);
};

interface FormatDateParams {
  date: string;
  locale: string;
  token?: keyof typeof FORMAT_OPTIONS;
}

export const formatDate = ({ date, locale, token = 'compact' }: FormatDateParams): string => {
  const normalizedLocale = locale.toLocaleLowerCase();
  if (!isLocaleCode(normalizedLocale)) return '';

  const options = FORMAT_OPTIONS[token];
  const customFormat = LOCALE_CUSTOM_FORMATS[normalizedLocale][token];
  const dateTimeFormat = new Intl.DateTimeFormat(locale, options);

  try {
    return (
      customFormat?.(dateTimeFormat.formatToParts(new Date(date))) ??
      dateTimeFormat.format(new Date(date))
    );
  } catch {
    return '';
  }
};
