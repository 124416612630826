import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import type {
  ClientDetails as ClientDetailsType,
  Document,
  Logo as LogoType,
  MerchantDetails,
  RelatedInvoice,
  RelatedQuote,
} from '../types';
import { usePdfPreviewContext } from '../pdf-preview-context';
import { Addresses } from './internals/addresses.tsx';
import { ClientDetails } from './internals/client-details.tsx';
import { DocumentDetails } from './internals/document-details.tsx';
import { Logo } from './internals/logo.tsx';
import { MerchantInformation } from './internals/merchant-information';
import { Reason } from './internals/reason.tsx';
import { Title } from './internals/title';
import styles from './header.strict-module.css';

interface HeaderProps {
  document: Document;
  merchant: MerchantDetails;
  client?: ClientDetailsType;
  logo?: LogoType;
  relatedInvoice?: RelatedInvoice;
  relatedQuote?: RelatedQuote;
}

export function Header({
  client,
  document,
  logo,
  merchant,
  relatedInvoice,
  relatedQuote,
}: HeaderProps): ReactNode {
  const { variant } = usePdfPreviewContext();

  if (variant === 'DE') {
    return (
      <header className={clsx(styles.header, styles.DE)}>
        <div className={styles.title}>
          <Title />
          <Logo logo={logo} />
        </div>
        <MerchantInformation merchant={merchant} />
        <div className={styles['sub-header']}>
          <div>
            <Addresses client={client} merchant={merchant} />
          </div>
          <div className={styles['full-details']}>
            <ClientDetails client={client} />
            <DocumentDetails
              document={document}
              relatedInvoice={relatedInvoice}
              relatedQuote={relatedQuote}
            />
          </div>
        </div>
        <Reason reason={document.reason} />
      </header>
    );
  }

  return (
    <header className={styles.header}>
      <div className={styles.title}>
        <div>
          <Title />
          <DocumentDetails
            document={document}
            relatedInvoice={relatedInvoice}
            relatedQuote={relatedQuote}
          />
        </div>
        <Logo logo={logo} />
      </div>
      <div className={styles['sub-header']}>
        <Addresses client={client} contactEmail={document.contactEmail} merchant={merchant} />
      </div>
      <Reason reason={document.reason} />
    </header>
  );
}
