import type { ReactNode } from 'react';
import type { Document, Item, Section as SectionType } from '../types';
import { TablePlaceholder } from '../placeholders';
import { Disclaimers } from './internals/disclaimers.tsx';
import { Section } from './internals/section.tsx';
import { DueDateDisclaimer } from './internals/due-date-disclaimer.tsx';
import { Totals } from './internals/totals.tsx';

interface TableProps {
  document: Document;
  items?: Item[];
  sections?: SectionType[];
}

export function Table({ document, items, sections }: TableProps): ReactNode {
  // Credit Notes do not have sections, only items, so we create a mock section for consistency
  const mockSections = items ? [{ id: 'mock-section', items }] : [];
  const documentSections = sections ?? mockSections;

  return (
    <TablePlaceholder>
      <div>
        <div className="mb-8">
          {documentSections.map((section, index) => (
            <Section
              index={index}
              key={section.id}
              section={section}
              showSubtotal={documentSections.length > 1}
            />
          ))}
        </div>
        <Totals document={document} />
        <Disclaimers document={document} />
      </div>
      <DueDateDisclaimer document={document} />
    </TablePlaceholder>
  );
}
