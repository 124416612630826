import { type ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import type { Section } from '../../types';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import styles from './vat-exemptions.strict-module.css';

interface VatExemptionsProps {
  legalCountry: string;
  sections?: Section[];
}

export function VatExemptions({ legalCountry, sections }: VatExemptionsProps): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage, type } = usePdfPreviewContext();

  const vatExemptionCodes = useMemo(() => {
    const reasons = new Set<string>();

    sections?.forEach(section => {
      section.items.forEach(item => {
        if (item.vatExemptionCode) {
          reasons.add(item.vatExemptionCode);
        }
      });
    });

    return [...reasons];
  }, [sections]);

  if (type === 'credit-note' || vatExemptionCodes.length === 0) {
    return null;
  }

  return (
    <div data-testid="pdf-preview-exemption-reasons">
      {vatExemptionCodes.map((code, index) => (
        <p
          className={cx('body-1', 'mb-8', styles.reason)}
          data-testid={`pdf-preview-exemption-reason-${index}`}
          key={`exemption-reasons-${code}`}
        >
          {formatMessage(
            { id: `pdf-preview.exemption.${legalCountry}.${code}` },
            { language: displayLanguage }
          )}
        </p>
      ))}
    </div>
  );
}
