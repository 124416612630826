import type { ReactElement, SVGProps } from 'react';

export function IconIdeal(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="#F5F5F5" height="40" rx="4" width="40" />
      <g clipPath="url(#clip0_6958_1946)">
        <path
          d="M20.6645 28H12.5111C11.7872 28 11.2 27.4097 11.2 26.6821V13.3179C11.2 12.5903 11.7872 12 12.5111 12H20.6645C28.4014 12 29.5554 17.0039 29.5554 19.9828C29.5554 25.1514 26.3938 28 20.6645 28ZM12.5111 12.4393C12.0262 12.4393 11.637 12.8305 11.637 13.3179V26.6821C11.637 27.1695 12.0262 27.5607 12.5111 27.5607H20.6645C26.1138 27.5607 29.1184 24.87 29.1184 19.9828C29.1184 13.4208 23.8193 12.4393 20.6645 12.4393H12.5111Z"
          fill="#050505"
        />
        <path
          d="M18.1924 20.6417C18.1036 20.6692 18.0216 20.6829 17.9329 20.6829V20.6898H17.3934V18.9326H17.8304C17.9807 18.9326 18.1036 18.9532 18.206 18.9944C18.3084 19.0356 18.3904 19.1042 18.4518 19.1797C18.5133 19.2552 18.5611 19.3582 18.5884 19.468C18.6157 19.5778 18.6294 19.7082 18.6294 19.8455C18.6294 20.0034 18.6089 20.1269 18.5679 20.2368C18.527 20.3466 18.4723 20.4289 18.4109 20.4976C18.3494 20.5662 18.2743 20.6143 18.1924 20.6417Z"
          fill="#050505"
        />
        <path d="M23.8261 20.1201L23.4779 19.0973H23.471L23.1091 20.1201H23.8261Z" fill="#050505" />
        <path
          clipRule="evenodd"
          d="M16.1985 26.2634V14.6083C16.1985 14.1278 16.5877 13.7297 17.0726 13.7297H21.2449C25.8269 13.7297 27.8141 16.4616 27.8141 19.9828C27.8141 23.6619 25.8269 26.2634 21.2449 26.2634H16.1985ZM18.4723 18.4933C18.3153 18.4384 18.1514 18.4109 17.9738 18.4109V18.4041H16.7925V21.1909H17.9875C18.1992 21.1909 18.3836 21.1497 18.5406 21.081C18.6977 21.0055 18.8274 20.9094 18.9299 20.7859C19.0323 20.6623 19.1074 20.5113 19.162 20.3397C19.2098 20.1681 19.2371 19.9828 19.2371 19.7769C19.2371 19.5435 19.203 19.3444 19.1415 19.1728C19.0733 19.0081 18.9845 18.864 18.8752 18.7473C18.7591 18.6374 18.6294 18.5482 18.4723 18.4933ZM20.2412 18.9257H21.7026V18.4041H19.6335V21.1909H21.7367V20.6761H20.2412V19.9965H21.5865V19.5229H20.2412V18.9257ZM23.792 18.4109L24.83 21.1977H24.1949L23.9832 20.58H22.9452L22.7267 21.1977H22.1121L23.1569 18.4109H23.792ZM25.8199 20.6829V18.4109H25.2121V21.1977H27.172V20.6829H25.8199Z"
          fill="#050505"
          fillRule="evenodd"
        />
        <path
          d="M13.9175 21.088C14.6227 21.088 15.1944 20.5133 15.1944 19.8044C15.1944 19.0955 14.6227 18.5209 13.9175 18.5209C13.2122 18.5209 12.6405 19.0955 12.6405 19.8044C12.6405 20.5133 13.2122 21.088 13.9175 21.088Z"
          fill="#050505"
        />
        <path
          d="M14.8803 26.2634C13.8082 26.2634 12.9478 25.3916 12.9478 24.3209V22.8039C12.9478 22.2685 13.378 21.8292 13.9174 21.8292C14.4501 21.8292 14.8871 22.2617 14.8871 22.8039V26.2634H14.8803Z"
          fill="#050505"
        />
      </g>
    </svg>
  );
}
