import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import type { Document } from '../../types';
import styles from './disclaimers.strict-module.css';

interface DisclaimersProps {
  document: Document;
}

export function Disclaimers({ document }: DisclaimersProps): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage, isDraft, variant } = usePdfPreviewContext();

  const shouldDisplayStampDutyDisclaimer =
    document.stampDutyAmount && document.stampDutyAmount !== '0.00';
  const shouldDisplayDraftDisclaimer = variant === 'IT' && isDraft;

  if (shouldDisplayStampDutyDisclaimer || shouldDisplayDraftDisclaimer) {
    return (
      <div className={styles.disclaimers}>
        {shouldDisplayStampDutyDisclaimer ? (
          <p
            className={styles['stamp-duty-disclaimer']}
            data-testid="pdf-preview-stamp-duty-disclaimer"
          >
            {formatMessage(
              { id: 'pdf-preview.placerholder-stamp-duty' },
              { language: displayLanguage }
            )}
          </p>
        ) : null}

        {shouldDisplayDraftDisclaimer ? (
          <p
            className={clsx('body-1', styles['draft-disclaimer'])}
            data-testid="pdf-preview-draft-disclaimer"
          >
            {formatMessage(
              { id: 'pdf-preview.draft.proforma-disclaimer' },
              { language: displayLanguage }
            )}
          </p>
        ) : null}
      </div>
    );
  }

  return null;
}
