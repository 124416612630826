import type { ReactNode } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import type { LocaleCode } from '@repo/shared-config/app/constants/locales';
import { flattenTranslations } from '../utils/translations';
import { loadMessages } from '../utils/messages';
import { detectLocale } from '../utils/detect-locale';

interface ScaIntlProviderProps {
  children: ReactNode;
  locale?: LocaleCode;
}

export function IntlProvider({ children, locale: propLocale }: ScaIntlProviderProps): ReactNode {
  const detectedLocale = detectLocale();

  const locale = propLocale || detectedLocale;
  const messages = flattenTranslations(loadMessages({ locale }));

  return (
    <ReactIntlProvider locale={locale} messages={messages}>
      {children}
    </ReactIntlProvider>
  );
}
