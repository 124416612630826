import type { PropsWithChildren, ReactNode } from 'react';
import { usePdfPreviewContext } from '../pdf-preview-context.tsx';
import { PLACEHOLDER_SECTIONS } from '../../constants/pdf-preview.ts';
import { Placeholder } from './internals/placeholder';
import styles from './additional-notes-placeholder.strict-module.css';

export function AdditionalNotesPlaceholder({ children }: PropsWithChildren): ReactNode {
  const { placeholders } = usePdfPreviewContext();

  if (placeholders?.includes(PLACEHOLDER_SECTIONS.ADDITIONAL_NOTES)) {
    return (
      <div className={styles.notes}>
        <Placeholder width="220px" />
        <Placeholder width="552px" />
      </div>
    );
  }

  return <>{children}</>;
}
