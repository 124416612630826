import type { ReactNode } from 'react';
import { formatDate, type FORMAT_OPTIONS } from '../../utils/format-date';

interface FormattedDateProps {
  date?: string;
  locale: string;
  token?: keyof typeof FORMAT_OPTIONS;
}

export function FormattedDate({ date, ...options }: FormattedDateProps): ReactNode {
  return date ? formatDate({ date, ...options }) : null;
}
