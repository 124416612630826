import type { ReactNode } from 'react';
import { PLACEHOLDER_SECTIONS } from '../../../constants/pdf-preview.ts';
import type { MerchantDetails } from '../../types';
import { Placeholder } from '../../placeholders';
import styles from './merchant-information.strict-module.css';

interface MerchantInformationProps {
  merchant: MerchantDetails;
}

export function MerchantInformation({ merchant }: MerchantInformationProps): ReactNode {
  const address = merchant.address;

  const dotSeparator = '·';
  const fullAddress =
    `${address.streetAddress}, ${address.zipCode} ${address.city}, ${address.countryCode}`.trim();

  return (
    <Placeholder height="8px" section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="218px">
      <p className="body-3" data-testid="pdf-preview-merchant-info-name">
        <span data-testid="pdf-preview-merchant-legal-name">{merchant.legalName}</span>
        <span className={styles.separator}>{dotSeparator}</span>
        <span data-testid="pdf-preview-merchant-info-address">{fullAddress}</span>
      </p>
    </Placeholder>
  );
}
