import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './skeleton.strict-module.css';

export function Skeleton({
  itemsCount = 3,
  id = 'related-payments-skeleton',
}: {
  itemsCount?: number;
  id?: string;
}): React.ReactElement {
  return (
    <div className={styles.loading} data-testid={id}>
      {Array.from({ length: itemsCount }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key -- index is safe here
        <div className={styles.skeleton} key={index}>
          <SkeletonLoader.Block height="48px" width="48px" />
          <div className={styles.lines}>
            <SkeletonLoader.Line width="136px" />
            <SkeletonLoader.Line width="78px" />
          </div>
        </div>
      ))}
    </div>
  );
}
