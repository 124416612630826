import { type ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { oppositeSign } from '../../../utils';
import type { Document } from '../../types';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import styles from './totals.strict-module.css';

interface TotalsProps {
  document: Document;
}

export function Totals({ document }: TotalsProps): ReactNode {
  const { formatMessage, formatNumber } = useIntl();
  const { displayLanguage, currency, type, variant } = usePdfPreviewContext();

  const showSubtotalsVatRates = type === 'invoice' && document.displayEachVatSubtotals;

  const discountLabel = useMemo(() => {
    if (document.discount?.type === 'absolute') {
      return formatMessage({ id: 'pdf-preview.amount-discount' }, { language: displayLanguage });
    }

    return formatMessage(
      { id: 'pdf-preview.total-discount' },
      {
        language: displayLanguage,
        discountPercentage: formatNumber(Number(document.discount?.value) || 0, {
          style: 'percent',
          minimumFractionDigits: 0,
          maximumFractionDigits: 1,
        }),
      }
    );
  }, [
    displayLanguage,
    document.discount?.type,
    document.discount?.value,
    formatMessage,
    formatNumber,
  ]);

  const withholdingTaxAmount = useMemo(() => {
    const amount = Math.abs(Number(document.withholdingTaxAmount));
    return type === 'credit-note' ? amount : -amount;
  }, [document.withholdingTaxAmount, type]);

  const withholdingTaxLabel = useMemo(() => {
    if (variant === 'ES') {
      return formatMessage(
        { id: 'pdf-preview.es-withholding-tax' },
        {
          language: displayLanguage,
          irpfRate: formatNumber(-Number(document.withholdingTax?.rate) || 0, {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }),
        }
      );
    }

    return formatMessage({ id: 'pdf-preview.withholding-tax' }, { language: displayLanguage });
  }, [displayLanguage, document.withholdingTax?.rate, formatMessage, formatNumber, variant]);

  return (
    <div>
      <table className={styles.totals} data-testid="pdf-preview-totals">
        <tbody>
          <tr data-testid="pdf-preview-total-excl-vat">
            <td className="body-1-bold">
              {formatMessage(
                { id: 'pdf-preview.total-without-vat' },
                { language: displayLanguage }
              )}
            </td>
            <td className="body-1">
              {formatNumber(Number(document.totalExcludingVat ?? '0.00'), {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
              })}
            </td>
          </tr>

          {document.discount && document.totalDiscount !== '0.00' ? (
            <>
              <tr data-testid="pdf-preview-total-discount">
                <td className="body-1">{discountLabel}</td>
                <td className="body-1">
                  {formatNumber(oppositeSign(Number(document.totalDiscount)), {
                    style: 'currency',
                    currency,
                    minimumFractionDigits: 2,
                  })}
                </td>
              </tr>
              <tr data-testid="pdf-preview-total-after-discount">
                <td className="body-1-bold">
                  {formatMessage(
                    { id: 'pdf-preview.total-after-discount' },
                    { language: displayLanguage }
                  )}
                </td>
                <td className="body-1">
                  {formatNumber(Number(document.discountedTotalExcludingVat) || 0, {
                    style: 'currency',
                    currency,
                    minimumFractionDigits: 2,
                  })}
                </td>
              </tr>
            </>
          ) : null}

          {document.welfareFundAmount && document.welfareFundAmount !== '0.00' ? (
            <tr data-testid="pdf-preview-pension-contribution">
              <td className="body-1-bold">
                {formatMessage(
                  { id: 'pdf-preview.pension-contribution' },
                  { language: displayLanguage }
                )}
              </td>
              <td className="body-1">
                {formatNumber(Number(document.welfareFundAmount), {
                  style: 'currency',
                  currency,
                  minimumFractionDigits: 2,
                })}
              </td>
            </tr>
          ) : null}

          {showSubtotalsVatRates && document.vatSubtotals ? (
            <>
              {document.vatSubtotals.map((subtotal, index) => (
                <tr
                  data-testid={`pdf-preview-vat-subtotal-${index}`}
                  //eslint-disable-next-line react/no-array-index-key -- no unique id
                  key={`vat-subtotals-${index}`}
                >
                  <td className="body-1">
                    {formatMessage(
                      { id: 'pdf-preview.vat-subtotal' },
                      {
                        language: displayLanguage,
                        VATRate: formatNumber(Number(subtotal.rate), {
                          style: 'percent',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1,
                        }),
                        subtotalPerVATPrice: formatNumber(Number(subtotal.totalExcludingVat), {
                          style: 'currency',
                          currency,
                        }),
                      }
                    )}
                  </td>
                  <td className="body-1">
                    {formatNumber(Number(subtotal.vatTotal), {
                      style: 'currency',
                      currency,
                      minimumFractionDigits: 2,
                    })}
                  </td>
                </tr>
              ))}
            </>
          ) : null}

          <tr data-testid="pdf-preview-total-vat">
            <td className="body-1-bold">
              {formatMessage({ id: 'pdf-preview.total-vat-amount' }, { language: displayLanguage })}
            </td>
            <td className="body-1">
              {formatNumber(Number(document.totalVat ?? '0.00'), {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
              })}
            </td>
          </tr>

          {document.withholdingTaxAmount && document.withholdingTaxAmount !== '0.00' ? (
            <tr data-testid="pdf-preview-withholding-tax">
              <td className="body-1-bold">{withholdingTaxLabel}</td>
              <td className="body-1">
                {formatNumber(withholdingTaxAmount, {
                  style: 'currency',
                  currency,
                  minimumFractionDigits: 2,
                })}
              </td>
            </tr>
          ) : null}

          {document.stampDutyAmount && document.stampDutyAmount !== '0.00' ? (
            <tr data-testid="pdf-preview-revenue-stamp">
              <td className="body-1-bold">
                {formatMessage({ id: 'pdf-preview.revenue-stamp' }, { language: displayLanguage })}
              </td>
              <td className="body-1">
                {formatNumber(Number(document.stampDutyAmount), {
                  style: 'currency',
                  currency,
                  minimumFractionDigits: 2,
                })}
              </td>
            </tr>
          ) : null}

          <tr className={styles['total-amount']} data-testid="pdf-preview-total-incl-vat">
            <td className="body-1-bold">
              {formatMessage({ id: 'pdf-preview.total-vat' }, { language: displayLanguage })}
            </td>
            <td className="body-1-bold">
              {formatNumber(Number(document.totalAmount ?? '0.00'), {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
              })}
            </td>
          </tr>

          {document.depositAmount ? (
            <>
              <tr data-testid="pdf-preview-deposit-amount">
                <td className="body-1-bold">
                  {formatMessage(
                    { id: 'pdf-preview.total-deposit' },
                    { language: displayLanguage }
                  )}
                </td>
                <td className="body-1">
                  {formatNumber(
                    type === 'credit-note'
                      ? Number(document.depositAmount)
                      : oppositeSign(Number(document.depositAmount)),
                    {
                      style: 'currency',
                      currency,
                      minimumFractionDigits: 2,
                      signDisplay: 'always',
                    }
                  )}
                </td>
              </tr>

              {document.totalAmountDue ? (
                <tr data-testid="pdf-preview-amount-due">
                  <td className="body-1-bold">
                    {formatMessage(
                      { id: 'pdf-preview.total-total-amount-due' },
                      { language: displayLanguage }
                    )}
                  </td>
                  <td className="body-1-bold">
                    {formatNumber(Number(document.totalAmountDue), {
                      style: 'currency',
                      currency,
                      minimumFractionDigits: 2,
                    })}
                  </td>
                </tr>
              ) : null}
            </>
          ) : null}
        </tbody>
      </table>
    </div>
  );
}
