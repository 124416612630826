import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { clsx } from 'clsx';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import styles from './reason.strict-module.css';

interface ReasonProps {
  reason?: string;
}

export function Reason({ reason }: ReasonProps): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage, type } = usePdfPreviewContext();

  if (type === 'credit-note' && reason) {
    return (
      <p data-testid="credit-note-pdf-preview-reason">
        <span className={clsx('body-1-bold', styles.label)}>
          {formatMessage(
            { id: 'pdf-preview.credit-note.reason-credit-note' },
            { language: displayLanguage }
          )}
        </span>
        <span className="body-1">{reason}</span>
      </p>
    );
  }

  return null;
}
