import type { ReactNode } from 'react';
import { PdfPreviewContext, type PdfPreviewContextProps } from './pdf-preview-context.tsx';
import { AdditionalNotes } from './additional-notes';
import { Conditions } from './conditions';
import { Footer } from './footer';
import { Header } from './header';
import { Layout } from './layout';
import { Table } from './table';
import type {
  ClientDetails,
  Document,
  Logo,
  MerchantDetails,
  RelatedInvoice,
  RelatedQuote,
} from './types';
import { usePdfPreviewMockData } from './use-pdf-preview-mock-data.ts';

export interface PdfPreviewProps extends Omit<PdfPreviewContextProps, 'clientLocale'> {
  document: Document;
  merchant: MerchantDetails;
  client?: ClientDetails;
  logo?: Logo;
  paymentLink?: string | null;
  relatedInvoice?: RelatedInvoice;
  relatedQuote?: RelatedQuote;
  className?: string;
}

export function PdfPreview({
  document,
  merchant,
  client,
  logo,
  paymentLink,
  relatedInvoice,
  relatedQuote,
  className,
  ...contextProps
}: PdfPreviewProps): ReactNode {
  const { mockClient, mockDocument } = usePdfPreviewMockData({
    document,
    merchant,
    settings: contextProps.settings,
    type: contextProps.type,
  });

  const mergedClient = contextProps.withMockData ? mockClient : client;
  const mergedDocument = contextProps.withMockData ? mockDocument : document;

  return (
    <PdfPreviewContext
      {...contextProps}
      clientLocale={mergedClient?.locale}
      document={mergedDocument}
    >
      <Layout
        className={className}
        footer={<Footer document={mergedDocument} merchant={merchant} paymentLink={paymentLink} />}
        header={
          <Header
            client={mergedClient}
            document={mergedDocument}
            logo={logo}
            merchant={merchant}
            relatedInvoice={relatedInvoice}
            relatedQuote={relatedQuote}
          />
        }
      >
        <AdditionalNotes message={mergedDocument.header} />
        <Table
          document={mergedDocument}
          items={mergedDocument.items}
          sections={mergedDocument.sections}
        />
        <AdditionalNotes message={mergedDocument.footer} />
        <Conditions document={mergedDocument} merchant={merchant} />
      </Layout>
    </PdfPreviewContext>
  );
}
