import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import type { ClientDetails, MerchantDetails, Document, DocumentType, Settings } from './types';

interface UsePdfPreviewMockDataArgs {
  document: Document;
  merchant: MerchantDetails;
  settings?: Settings;
  type: DocumentType;
}

interface UsePdfPreviewMockDataReturnValue {
  mockClient?: ClientDetails;
  mockDocument: Document;
}

export function usePdfPreviewMockData({
  document,
  merchant,
  settings,
  type,
}: UsePdfPreviewMockDataArgs): UsePdfPreviewMockDataReturnValue {
  const { formatMessage } = useIntl();

  const nextNumberFormatted =
    type === 'quote' ? settings?.quoteNextNumberFormatted : settings?.invoiceNextNumberFormatted;

  const mockDocument: Document = useMemo(
    () => ({
      locale: merchant.locale,
      number:
        settings?.numberingMode === 'automatic'
          ? nextNumberFormatted
          : formatMessage({ id: 'pdf-preview.invoice-example.invoice-number' }),
      currency: 'EUR',
      issueDate: '2030-01-01',
      dueDate: type === 'quote' ? undefined : '2030-01-15',
      expiryDate: type === 'quote' ? '2030-01-15' : undefined,
      performanceStartDate: '2030-01-01',
      contactEmail: merchant.contactEmail,
      bic: document.bic,
      iban: document.iban,
      reference: document.reference,
      beneficiaryName: document.beneficiaryName,
      header: document.header,
      footer: document.footer,
      termsAndConditions: document.termsAndConditions,
      totalVat: formatMessage({ id: 'pdf-preview.invoice-example.item-vat-total' }),
      totalExcludingVat: formatMessage({
        id: 'pdf-preview.invoice-example.item-total-without-vat',
      }),
      totalAmount: formatMessage({ id: 'pdf-preview.invoice-example.invoice-total' }),
      sections: [
        {
          id: 'section-999',
          items: [
            {
              id: 'item-999',
              title: formatMessage({ id: 'pdf-preview.invoice-example.item-title' }),
              description: formatMessage({
                id: 'pdf-preview.invoice-example.item-description',
              }),
              quantity: formatMessage({ id: 'pdf-preview.invoice-example.item-quantity' }),
              unitPrice: formatMessage({ id: 'pdf-preview.invoice-example.item-price' }),
              vatRate: formatMessage({ id: 'pdf-preview.invoice-example.item-vat-amount' }),
              totalVat: formatMessage({ id: 'pdf-preview.invoice-example.item-vat-total' }),
              totalExcludingVat: formatMessage({
                id: 'pdf-preview.invoice-example.item-total-without-vat',
              }),
              subTotal: formatMessage({ id: 'pdf-preview.invoice-example.item-total' }),
              totalAmount: formatMessage({ id: 'pdf-preview.invoice-example.item-total' }),
            },
          ],
        },
      ],
    }),
    [
      document.beneficiaryName,
      document.bic,
      document.iban,
      merchant.contactEmail,
      merchant.locale,
      document.reference,
      document.header,
      document.footer,
      document.termsAndConditions,
      formatMessage,
      nextNumberFormatted,
      settings?.numberingMode,
      type,
    ]
  );

  const mockClient: ClientDetails = useMemo(
    () => ({
      billingAddress: {
        streetAddress: formatMessage({
          id: 'pdf-preview.invoice-example.client-address-line1',
        }),
        city: formatMessage({ id: 'pdf-preview.invoice-example.client-city' }),
        zipCode: formatMessage({ id: 'pdf-preview.invoice-example.client-zipcode' }),
        countryCode: formatMessage({
          id: 'pdf-preview.invoice-example.client-countrycode',
        }),
      },
      name: formatMessage({ id: 'pdf-preview.invoice-example.client-name' }),
      vatNumber: formatMessage({ id: 'pdf-preview.invoice-example.client-vat-number' }),
      locale: merchant.locale,
      deliveryAddress: {
        streetAddress: formatMessage({
          id: 'pdf-preview.invoice-example.client-delivery-address-line1',
        }),
        city: formatMessage({ id: 'pdf-preview.invoice-example.client-delivery-city' }),
        zipCode: formatMessage({
          id: 'pdf-preview.invoice-example.client-delivery-zipcode',
        }),
        countryCode: formatMessage({
          id: 'pdf-preview.invoice-example.client-delivery-countrycode',
        }),
      },
    }),
    [merchant.locale, formatMessage]
  );

  return {
    mockDocument,
    mockClient,
  };
}
