import cx from 'clsx';
import type { ReactNode } from 'react';
import { PLACEHOLDER_SECTIONS } from '../../../constants/pdf-preview.ts';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import { Placeholder } from '../../placeholders';
import styles from './terms-and-conditions-link.strict-module.css';

export function TermsAndConditionsLink(): ReactNode {
  const { settings } = usePdfPreviewContext();

  return (
    <Placeholder section={PLACEHOLDER_SECTIONS.TERMS_AND_CONDITIONS} width="438px">
      {settings?.tcLinkUrl ? (
        <a
          className={cx(styles.termsAndConditionsLink, 'body-1')}
          data-testid="pdf-preview-tc-link"
          href={settings.tcLinkUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          {settings.tcLinkText}
        </a>
      ) : null}
    </Placeholder>
  );
}
