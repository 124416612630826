import type { ErrorObject } from './sca-errors';

export function calculateVerificationTimeout(
  UTCTimes: ErrorObject['deviceVerificationEmail'] | undefined
): number {
  const EMAIL_VERIFICATION_TIMEOUT = 5 * 60 * 1000;
  if (!UTCTimes) return EMAIL_VERIFICATION_TIMEOUT;

  if (
    !UTCTimes.createdAt ||
    !UTCTimes.expiresAt ||
    isNaN(Date.parse(UTCTimes.createdAt)) ||
    isNaN(Date.parse(UTCTimes.expiresAt))
  ) {
    return EMAIL_VERIFICATION_TIMEOUT;
  }

  const createdAtDate = new Date(UTCTimes.createdAt);
  const expiresAtDate = new Date(UTCTimes.expiresAt);
  return expiresAtDate.getTime() - createdAtDate.getTime();
}
