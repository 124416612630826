import cx from 'clsx';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { PLACEHOLDER_SECTIONS } from '../../../constants/pdf-preview.ts';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import { Placeholder } from '../../placeholders';
import styles from './document-condition.strict-module.css';

export function DocumentConditions(): ReactNode {
  const { type } = usePdfPreviewContext();

  if (type === 'invoice') {
    return <InvoiceConditions />;
  }

  return null;
}

function InvoiceConditions(): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage, settings } = usePdfPreviewContext();

  return (
    <div className="mb-8">
      {settings?.transactionType ? (
        <p className="body-1" data-testid="pdf-preview-transaction-type">
          {formatMessage(
            { id: 'pdf-preview.transaction-type.label' },
            { language: displayLanguage }
          )}
          {formatMessage(
            {
              id: `pdf-preview.transaction-type.${settings.transactionType}`,
            },
            { language: displayLanguage }
          )}
        </p>
      ) : null}

      {settings?.vatPaymentCondition && settings.vatPaymentCondition !== 'exempt' ? (
        <p className="body-1" data-testid="pdf-preview-vat-payment-condition">
          {formatMessage(
            { id: 'pdf-preview.payment-condition.label' },
            { language: displayLanguage }
          )}
          {formatMessage(
            { id: `pdf-preview.payment-condition.${settings.vatPaymentCondition}` },
            { language: displayLanguage }
          )}
        </p>
      ) : null}

      <Placeholder section={PLACEHOLDER_SECTIONS.TERMS_AND_CONDITIONS} width="100%">
        {settings?.discountConditions ? (
          <p
            className={cx('body-1', styles.condition)}
            data-testid="pdf-preview-discount-condition"
          >
            {settings.discountConditions}
          </p>
        ) : null}
      </Placeholder>

      <Placeholder section={PLACEHOLDER_SECTIONS.TERMS_AND_CONDITIONS} width="220px">
        {settings?.latePaymentPenalties ? (
          <p className={cx('body-1', styles.condition)} data-testid="pdf-preview-payment-penalty">
            {settings.latePaymentPenalties}
          </p>
        ) : null}
      </Placeholder>

      <Placeholder section={PLACEHOLDER_SECTIONS.TERMS_AND_CONDITIONS} width="552px">
        {settings?.legalFixedCompensation ? (
          <p
            className={cx('body-1', styles.condition)}
            data-testid="pdf-preview-fixed-compensation"
          >
            {settings.legalFixedCompensation}
          </p>
        ) : null}
      </Placeholder>
    </div>
  );
}
