import { Button, Popup } from '@repo/design-system-kit';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSegment } from '@repo/poly-hooks/segment';
import { AuthenticationFailedSvg } from '../../../svgs/authentication-failed';
import styles from './sca.strict-module.css';

interface RefusedProps {
  onClose?: () => void;
  onConfirm: () => void;
}

export function Refused({ onClose, onConfirm }: RefusedProps): React.ReactNode {
  // NOTE -- this is needed until we have a better way to handle the portal container in qunit tests
  // https://www.notion.so/qonto/React-popover-library-used-within-react-aria-components-does-mount-popovers-modals-outside-of-qunit--12631ee4c696806e87dbef81e9a27922
  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;

  const segment = useSegment();
  const [isOpen, setIsOpen] = useState(true);
  const { formatMessage } = useIntl();

  useEffect(() => {
    segment.track('sca_enforcement_closed', { origin: 'authentication_failed' });
  }, [segment]);

  const handleClose = (): void => {
    onClose?.();
    setIsOpen(false);
  };
  const handleConfirm = (): void => {
    onConfirm();
  };

  return (
    <Popup.Root
      aria-label={formatMessage({ id: 'sca.sensitive-actions.generic.refused.title' })}
      data-testid="sca-refused-popup"
      isOpen={isOpen}
      portalContainer={TEST_PORTAL_CONTAINER}
      role="dialog"
    >
      <Popup.CloseButton onPress={handleClose} />
      <Popup.Header>
        <figure className={styles.illustrationWrap}>
          <AuthenticationFailedSvg />
        </figure>
        <Popup.Title>
          <FormattedMessage id="sca.sensitive-actions.generic.refused.title" />
        </Popup.Title>
      </Popup.Header>
      <Popup.Body>
        <p className="body-2">
          <FormattedMessage id="sca.sensitive-actions.generic.refused.subtitle" />
        </p>
      </Popup.Body>
      <Popup.Footer>
        <Button data-testid="sca-refused-retry" onPress={handleConfirm} variant="primary">
          <FormattedMessage id="sca.sensitive-actions.generic.refused.button" />
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
