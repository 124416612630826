import type { HTMLProps, ReactNode } from 'react';
import { clsx } from 'clsx';
import { useIntl } from 'react-intl';
import { CURRENCIES } from '../../../constants/pdf-preview.ts';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import styles from './payment-details.strict-module.css';

interface PaymentDetailsProps {
  beneficiaryName?: string;
  bic?: string;
  iban?: string;
  reference?: string;
}

export function PaymentDetails({
  beneficiaryName,
  bic,
  iban,
  reference,
}: PaymentDetailsProps): ReactNode {
  const { formatMessage } = useIntl();
  const { currency, displayLanguage } = usePdfPreviewContext();

  const isForeignCurrency = currency !== CURRENCIES.DEFAULT;

  return (
    <div data-testid="pdf-preview-payment-details">
      <h2 className="label-1 mb-8">
        {formatMessage({ id: 'pdf-preview.payment-details' }, { language: displayLanguage })}
      </h2>

      <div className={styles.details}>
        <PaymentDetail
          data-testid="pdf-preview-payment-details-beneficiary"
          label={formatMessage(
            { id: 'pdf-preview.beneficiary-name' },
            { language: displayLanguage }
          )}
          value={beneficiaryName}
        />

        <PaymentDetail
          data-testid="pdf-preview-payment-details-bic"
          label={formatMessage({ id: 'pdf-preview.bic' }, { language: displayLanguage })}
          value={bic}
        />

        {isForeignCurrency ? (
          <PaymentDetail
            data-testid="pdf-preview-payment-details-intermediary-bic"
            label={formatMessage(
              { id: 'pdf-preview.intermediary-bic.label' },
              { language: displayLanguage }
            )}
            value={formatMessage(
              { id: 'pdf-preview.intermediary-bic.value' },
              { language: displayLanguage }
            )}
          />
        ) : null}

        <PaymentDetail
          data-testid="pdf-preview-payment-details-iban"
          label={formatMessage({ id: 'pdf-preview.iban' }, { language: displayLanguage })}
          value={iban}
        />

        <PaymentDetail
          data-testid="pdf-preview-payment-details-reference"
          label={formatMessage({ id: 'pdf-preview.reference' }, { language: displayLanguage })}
          value={reference}
        />
      </div>
    </div>
  );
}

interface PaymentDetailProps extends HTMLProps<HTMLParagraphElement> {
  label: string;
  value?: string;
}

function PaymentDetail({ label, value, ...props }: PaymentDetailProps): ReactNode {
  return (
    <p className="mb-2" {...props}>
      <span className={clsx('body-1-bold', styles.label)}>{label}</span>
      <span className="body-1">{value}</span>
    </p>
  );
}
