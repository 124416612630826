import cx from 'clsx';
import type { PropsWithChildren, ReactNode, CSSProperties } from 'react';
import { DEFAULT_COLORS } from '../../constants/pdf-preview.ts';
import { usePdfPreviewContext } from '../pdf-preview-context.tsx';
import styles from './layout.strict-module.css';

interface LayoutProps {
  header: ReactNode;
  footer: ReactNode;
  className?: string;
}

export function Layout({
  header,
  footer,
  className,
  children,
}: PropsWithChildren<LayoutProps>): ReactNode {
  const { settings, type, variant } = usePdfPreviewContext();

  const {
    colorText = DEFAULT_COLORS.colorText,
    colorTextAlt = DEFAULT_COLORS.colorTextAlt,
    colorTheme = DEFAULT_COLORS.colorTheme,
  } = settings ?? {};

  const style = {
    '--invoice-color-text': colorText,
    '--invoice-color-text-alt': colorTextAlt,
    '--invoice-color-theme': colorTheme,
  } as CSSProperties;

  return (
    <div className={cx(styles.document, styles[type], styles[variant], className)} style={style}>
      <div className={styles.layout}>
        {header}
        <main className={styles.main}>{children}</main>
      </div>
      {footer}
    </div>
  );
}
