import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { clsx } from 'clsx';
import { oppositeSign } from '../../../utils';
import { formatAsPercent } from '../../../utils/format-as-percent.ts';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import type { Discount } from '../../types';
import styles from './item-discount-row.strict-module.css';

interface ItemDiscountRowProps {
  discount?: Discount;
  totalDiscount?: string;
  hideBorder?: boolean;
  index: number;
}

export function ItemDiscountRow({
  discount,
  hideBorder,
  index,
  totalDiscount,
}: ItemDiscountRowProps): ReactNode {
  const { formatMessage, formatNumber } = useIntl();
  const { currency, displayLanguage } = usePdfPreviewContext();

  if (discount) {
    const discountAmount = discount.amount || totalDiscount;

    return (
      <tr
        className={clsx(styles.discount, hideBorder && styles['no-bottom'])}
        data-testid={`pdf-preview-items-row-discount-${index}`}
      >
        <td className="body-1" colSpan={4}>
          {discount.type === 'percentage' ? (
            <p className={styles.description} data-testid="pdf-preview-item-discount-percentage">
              {formatMessage(
                { id: 'pdf-preview.percentage-discount' },
                {
                  language: displayLanguage,
                  discountPercentage: formatAsPercent(discount.value),
                }
              )}
            </p>
          ) : (
            <p className={styles.description} data-testid="pdf-preview-item-discount-amount">
              {formatMessage({ id: 'pdf-preview.amount-discount' }, { language: displayLanguage })}
            </p>
          )}
        </td>
        <td className="body-1" data-testid="pdf-preview-item-discount-total-amount">
          {discountAmount ? (
            <p className={styles.description}>
              {formatNumber(oppositeSign(Number(discountAmount) || 0), {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
              })}
            </p>
          ) : null}
        </td>
      </tr>
    );
  }
}
