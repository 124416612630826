import type { HTMLProps, PropsWithChildren, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import type { Address as AddressType, PlaceholderSection } from '../../types';
import { Placeholder } from '../../placeholders';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import styles from './address.strict-module.css';

interface AddressProps extends Omit<HTMLProps<HTMLDivElement>, 'label'> {
  label: ReactNode;
  address: AddressType;
  placeholderSection?: PlaceholderSection;
}

export function Address({
  label,
  address,
  placeholderSection,
  children,
  ...props
}: PropsWithChildren<AddressProps>): ReactNode {
  const { formatMessage } = useIntl();
  const { variant } = usePdfPreviewContext();

  if (variant === 'DE') {
    const countryName = address.countryCode
      ? formatMessage({ id: `countries.${address.countryCode}` })
      : '';

    return (
      <address className={styles.address} {...props}>
        <Placeholder className="mb-8" height="11px" section={placeholderSection} width="153px">
          <div data-testid="pdf-preview-addresses-label">{label}</div>
        </Placeholder>
        <div className={styles.content}>
          <Placeholder section={placeholderSection} width="109px">
            <p className="body-2" data-testid="pdf-preview-addresses-street-address">
              {address.streetAddress}
            </p>
          </Placeholder>
          <Placeholder section={placeholderSection} width="77px">
            {address.zipCode || address.city ? (
              <p className="body-2" data-testid="pdf-preview-addresses-city">
                {address.zipCode} {address.city}
              </p>
            ) : null}
          </Placeholder>
          <Placeholder section={placeholderSection} width="137px">
            {countryName ? (
              <p className="body-2" data-testid="pdf-preview-addresses-country">
                {countryName}
              </p>
            ) : null}
          </Placeholder>
          {children}
        </div>
      </address>
    );
  }

  const countrySeparator = ', ';
  const showSeparator = (address.zipCode || address.city) && address.countryCode;

  return (
    <address className={styles.address} {...props}>
      <Placeholder className="mb-8" height="11px" section={placeholderSection} width="153px">
        <div data-testid="pdf-preview-addresses-label">{label}</div>
      </Placeholder>
      <div className={styles.content}>
        <Placeholder section={placeholderSection} width="109px">
          <p className="body-2" data-testid="pdf-preview-addresses-street-address">
            {address.streetAddress}
          </p>
        </Placeholder>
        <Placeholder section={placeholderSection} width="77px">
          {address.zipCode || address.city || address.countryCode ? (
            <p className="body-2" data-testid="pdf-preview-addresses-city">
              {address.zipCode} {address.city}
              {showSeparator ? countrySeparator : null}
              {address.countryCode}
            </p>
          ) : null}
        </Placeholder>
        {children}
      </div>
    </address>
  );
}
