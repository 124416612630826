import { clsx } from 'clsx';
import type { HTMLProps, ReactNode } from 'react';
import styles from './placeholder.strict-module.css';

export interface PlaceholderProps extends HTMLProps<HTMLSpanElement> {
  width?: string;
  height?: string;
}

export function Placeholder({
  className,
  style = {},
  width = '100%',
  height = '10px',
  ...rest
}: PlaceholderProps): ReactNode {
  const mergedStyle = { ...style, width, height };

  return (
    <span
      className={clsx(styles.placeholder, className)}
      data-testid="pdf-preview-placeholder"
      style={mergedStyle}
      {...rest}
    />
  );
}
