import cx from 'clsx';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ORIGIN } from '../../../constants/pdf-preview';
import { usePdfPreviewContext } from '../../pdf-preview-context';
import styles from './title.strict-module.css';

export function Title(): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage, isDeposit, isDraft, origin, settings, type, variant } =
    usePdfPreviewContext();

  const title = useMemo(() => {
    if (type === 'credit-note') {
      return formatMessage({ id: 'pdf-preview.credit-note.title' }, { language: displayLanguage });
    }

    if (type === 'quote') {
      if (settings?.quoteTitle) {
        return settings.quoteTitle;
      }
      return formatMessage({ id: 'pdf-preview.quote-title' }, { language: displayLanguage });
    }

    if (isDraft && !(origin === ORIGIN.MODAL || origin === ORIGIN.FORM)) {
      return formatMessage(
        { id: isDeposit ? 'pdf-preview.draft-deposit-title' : 'pdf-preview.draft.title' },
        { language: displayLanguage }
      );
    }

    if (isDeposit) {
      return formatMessage({ id: 'pdf-preview.deposit-title' }, { language: displayLanguage });
    }

    if (settings?.invoiceTitle) {
      return settings.invoiceTitle;
    }

    return formatMessage({ id: 'pdf-preview.title' }, { language: displayLanguage });
  }, [
    displayLanguage,
    formatMessage,
    isDeposit,
    type,
    isDraft,
    origin,
    settings?.quoteTitle,
    settings?.invoiceTitle,
  ]);

  return (
    <p className={cx(styles.title, styles[variant])} data-testid="pdf-preview-title">
      {title}
    </p>
  );
}
