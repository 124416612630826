import { useIntl } from 'react-intl';

export function useTranslatePlaceholders(): {
  translatePlaceholders: (value: string, local?: string) => string;
} {
  const intl = useIntl();

  const getDynamicPlaceholders = (locale?: string): Record<string, string> => {
    const now = new Date();
    const nextMonth = new Date().setMonth(now.getMonth() + 1);
    const lastMonth = new Date().setMonth(now.getMonth() - 1);

    const format = (options: Intl.DateTimeFormatOptions, date: number | Date): string => {
      return new Intl.DateTimeFormat(locale || intl.locale, options).format(date);
    };

    return {
      [intl.formatMessage({ id: 'dynamic-values.dates.current-month' })]: format(
        { month: 'long' },
        now
      ),
      [intl.formatMessage({ id: 'dynamic-values.dates.current-year' })]: format(
        { year: 'numeric' },
        now
      ),
      [intl.formatMessage({ id: 'dynamic-values.dates.next-month' })]: format(
        { month: 'long' },
        nextMonth
      ),
      [intl.formatMessage({ id: 'dynamic-values.dates.last-month' })]: format(
        { month: 'long' },
        lastMonth
      ),
    };
  };

  const translatePlaceholders = (value: string, locale?: string): string => {
    if (!value) return value;

    const placeholders = getDynamicPlaceholders(locale);
    const placeholdersArray = Object.keys(placeholders);

    if (
      placeholdersArray.some(placeholder => value.toLowerCase().includes(placeholder.toLowerCase()))
    ) {
      return placeholdersArray.reduce((result, placeholder) => {
        const replacement = placeholders[placeholder];
        if (!replacement) return result;

        const regexp = new RegExp(placeholder, 'gi');
        return result.replace(regexp, replacement);
      }, value);
    }

    return value;
  };

  return { translatePlaceholders };
}
