import { Fragment, type ReactNode } from 'react';
import { clsx } from 'clsx';
import type { Item } from '../../types';
import { ItemRow } from './item-row.tsx';
import { ItemDiscountRow } from './item-discount-row.tsx';
import { ItemLinksRow } from './item-links-row.tsx';
import styles from './item-table.strict-module.css';
import { ItemHeader } from './item-header.tsx';

interface ItemTableProps {
  items: Item[];
}

export function ItemTable({ items }: ItemTableProps): ReactNode {
  return (
    <table className={clsx('mb-8', styles.table)}>
      <ItemHeader />
      <tbody>
        {items.map((item, index) => {
          const hasDiscount = Boolean(item.discount);
          const hasLinks = (item.links?.length ?? 0) > 0;

          return (
            <Fragment key={item.id}>
              <ItemRow hideBorder={hasDiscount || hasLinks} index={index} item={item} />
              <ItemDiscountRow
                discount={item.discount}
                hideBorder={hasLinks}
                index={index}
                totalDiscount={item.totalDiscount}
              />
              <ItemLinksRow links={item.links} />
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
}
